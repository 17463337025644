import request from '@/utils/request'

// api地址
const api = {
  all: '/frm/corp/listAdmin',
  save: '/frm/corp',
  update: '/frm/corp/update',
  delete: '/frm/corp/',
  deleteBatch: '/frm/corp/batch',
  page: '/frm/corp/listAdmin',
  verifyPage: '/frm/corp/verify/page',
  agree: '/frm/corp/verify/agree',
  refuse: '/frm/corp/verify/refuse',
  users: '/frm/corpUser/page',
  saveUser: '/frm/corpUser',
  corpInfo: '/frm/portal/corp/'
}

/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function getCorpInfo(id) {
  return new Promise((resolve, reject) => {
    request.get(api.corpInfo + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function verifyPage(params) {
  return new Promise((resolve, reject) => {
    request.get(api.verifyPage, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

// 通过认证
export function agree(data) {
  return new Promise((resolve, reject) => {
    request.post(api.agree, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

// 驳回认证
export function refuse(data) {
  return new Promise((resolve, reject) => {
    request.post(api.refuse, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
