<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="1000"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改门店信息' : '新建门店信息'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 4 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 20 }, sm: { span: 24 } }"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item label="门店名称:" name="shopName">
            <a-input
              v-model:value="form.shopName"
              placeholder="请输入门店名称"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="联系人:" name="linkman">
            <a-input
              v-model:value="form.linkman"
              placeholder="请输入联系人"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="状态:" name="status">
            <a-radio-group v-model:value="form.status">
              <a-radio :value="1">启用</a-radio>
              <a-radio :value="0">禁用</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="门店区域:" name="region">
            <a-cascader
              allow-clear
              style="width: 100%"
              v-model:value="areaRange"
              :options="areaOptions"
              placeholder="请选择"
            />
          </a-form-item>
          <a-form-item label="详细地址:" name="address">
            <a-input
              v-model:value="form.address"
              placeholder="请输入详细地址"
              allow-clear
            />
          </a-form-item>          
          <a-form-item label="门店logo:" name="logoPic">
            <a-upload
              list-type="picture-card"
              v-model:file-list="logoPicList"
              @preview="handlePreview"
              :customRequest="({ file }) => uploadFile(file, 'logoPic')"
              @remove="removeFile"
            >
              <div v-if="logoPicList.length < 1">
                <plus-outlined />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              :bodyStyle="{ paddingTop: '50px' }"
              @cancel="previewVisible = false"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
          <a-form-item label="门店简介:" name="summary">
            <a-textarea
              :rows="5"
              placeholder="请输入门店简介"
              v-model:value="form.summary"
            ></a-textarea>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <!-- <a-form-item label="团队ID:" name="corpId">
            <a-select v-model:value="form.corpId" placeholder="请选择团队" allow-clear>
              <a-select-option
                v-for="item in activityStoreList"
                :key="item.corpId"
                :value="item.corpId"
              >
                {{ item.corpName }}
              </a-select-option>
            </a-select>
          </a-form-item> -->
          <a-form-item label="联系电话:" name="phone">
            <a-input
              v-model:value="form.phone"
              placeholder="请输入联系电话"
              allow-clear
            />
          </a-form-item>
          <a-form-item label="营业时间:" name="shopHours">
            <a-time-range-picker
              v-model:value="shopHoursRange"
              format="HH:mm"
              valueFormat="HH:mm"
            />
          </a-form-item>

          <a-form-item label="自提核销:" name="isCheck">
            <a-radio-group v-model:value="form.isCheck">
              <a-radio
                v-for="(item, index) in checkList"
                :key="index"
                :value="item.value"
                >{{ item.label }}</a-radio
              >
            </a-radio-group>
          </a-form-item>
          <a-form-item label="门店坐标:" name="coordinate">
            <div>
              <a-button type="primary" @click="coordinate()">地图选点</a-button>
              <span style="margin-left: 10px"
                >经度: {{ form.longitude }} 纬度: {{ form.latitude }}</span
              >
            </div>
          </a-form-item>
          <a-form-item label="排序:" name="sort" style="margin-bottom: 0">
            <a-input-number
              style="width: 100%"
              v-model:value="form.sort"
              :min="0"
            ></a-input-number>
          </a-form-item>
          <a-form-item
            style="margin-bottom: 0"
            :wrapper-col="{ offset: 4, span: 20 }"
          >
            <div>数字越小越靠前</div>
          </a-form-item>
          <a-form-item label="门店海报:" name="posterPic">
            <a-upload
              list-type="picture-card"
              v-model:file-list="posterPicList"
              @preview="handlePreview"
              :customRequest="({ file }) => uploadFile(file, 'posterPic')"
              @remove="removeFile"
            >
              <div v-if="posterPicList.length < 1">
                <plus-outlined />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              :bodyStyle="{ paddingTop: '50px' }"
              @cancel="previewVisible = false"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- 门店选址弹出层 -->
    <a-modal
      :width="950"
      :visible="showPostion"
      title="地图选址"
      :body-style="{ paddingBottom: '8px' }"
      @cancel="cancelPosition"
      @ok="setPosition"
    >
      <Map ref="map" v-model:position="position" id="pointAddress" />
    </a-modal>
  </a-modal>
</template>

<script>
import regions from '@/utils/regions.js'
import * as corpShopApi from '@/api/store/shop.js'
import * as corpAPi from '@/api/corp/corp.js'
import Map from '@/components/map/addressPicker.vue'
import setting from '@/config/setting'
import { PlusOutlined } from '@ant-design/icons-vue'
function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'shopEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    PlusOutlined,
    Map
  },
  data() {
    return {
      areaRange: [],
      shopHoursRange: [],
      checkList: [
        {
          label: '支持',
          value: 1
        },
        {
          label: '不支持',
          value: 0
        }
      ],
      // 表单数据
      areaOptions: regions.cityData,
      form: Object.assign({}, this.data),
      uploadUrl: setting.uploadUrl,
      logoPicList: [],
      posterPicList: [],
      previewVisible: false,
      previewImage: '',
      showPostion: false,
      position: {},
      // 编辑弹窗表单验证规则
      rules: {
        // groupId: [
        //   {
        //     required: true,
        //     message: '请输入站点组名称',
        //     type: 'string',
        //     trigger: 'blur'
        //   },
        //   {
        //     required: true,
        //     message: '请输入所属运营商',
        //     type: 'string',
        //     trigger: 'blur'
        //   }
        // ]
      },
      activityStoreList: [],
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false
    }
  },
  created() {
    this.activityStore()
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({ status: 1, isCheck: 1 }, this.data)
        this.isUpdate = true
        if (this.form.logoPic) {
          this.logoPicList[0] = {
            uid: '-4',
            name: 'image.png',
            status: 'done',
            url: this.form.logoPic
          }
        }
        if (this.form.posterPic) {
          this.posterPicList[0] = {
            uid: '-3',
            name: 'image.png',
            status: 'done',
            url: this.form.posterPic
          }
        }
        if (this.form.shopHours) {
          this.shopHoursRange = this.form.shopHours.split('-')
        }
        if (this.form.region) {
          this.areaRange = this.form.region.split(',')
        }
      } else {
        this.form = { status: 1, isCheck: 1 }
        this.logoPicList = []
        this.posterPicList = []
        this.shopHoursRange = []
        this.areaRange = []
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  methods: {
    // /* 查询活动商家 */
    activityStore() {
      corpAPi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.activityStoreList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    coordinate() {
      this.position = this.form
      this.showPostion = true
    },
    setPosition() {
      const data = this.$refs.map.form
      this.form.longitude = data.longitude
      this.form.latitude = data.latitude
      this.form.address = data.fullAddress
      this.showPostion = false
    },
    cancelPosition() {
      this.showPostion = false
    },
    removeFile(file) {
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (file.uid === '-4') {
              this.logoPicList = []
              this.form.logoPic = ''
            }
            if (file.uid === '-3') {
              this.posterPicList = []
              this.form.posterPic = ''
            }
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'logoPic') {
              this.logoPicList[0] = {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
              this.form.logoPic = res.data.location
            }
            if (name === 'posterPic') {
              this.posterPicList[0] = {
                uid: '-3',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
              this.form.posterPic = res.data.location
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    /* 保存编辑 */
    save() {
      if (this.areaRange && this.areaRange.length > 0) {
        this.form.region = this.areaRange.join(',')
      }
      if (this.shopHoursRange && this.shopHoursRange.length > 0) {
        this.form.shopHours = this.shopHoursRange.join('-')
      }
      if (!this.isUpdate) {
        this.form.corpId = this.getCorpId()
      }

      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          corpShopApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>
