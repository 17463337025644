<!-- 编辑弹窗 -->
<template>
  <div style="width: 100%">
    <a-row :gutter="6">
      <a-col :sm="12">
        <a-row justify="center" align="middle">
          <a-col :sm="3"> 坐标：</a-col>
          <a-col :sm="21">
            <a-input
              v-model:value="form.lnglat"
              :disabled="true"
              style="background-color: #fff; color: #000; cursor: auto"
            />
          </a-col>
        </a-row>
        <div :id="id" style="width: 100%; height: 300px; margin-top: 10px"></div
      ></a-col>
      <a-col :sm="12">
        <a-row justify="center" align="middle">
          <a-col :sm="3"> 地址：</a-col>
          <a-col :sm="21">
            <a-input-search
              placeholder="请输入地址搜索"
              v-model:value="form.fullAddress"
              @search="search"
              enter-button
            />
          </a-col>
        </a-row>
        <div class="scroll-list">
          <div
            class="item"
            v-for="(item, index) in addressList"
            :key="index"
            @click="selectAddress(item, index)"
          >
            <div>
              <div class="title">{{ item.name }}</div>
              <div class="desc">
                {{ item.distance }}m内 | {{ item.address }}
              </div>
            </div>
            <div v-if="index === activeIndex">
              <check-outlined style="font-size: 20px; color: #0096d0" />
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import AMap from 'AMap'
import { CheckOutlined } from '@ant-design/icons-vue'
export default {
  name: 'AddressPicker',
  emits: ['done'],
  props: {
    // 修改回显的数据
    position: {},
    id: String,
    iconUrl: {
      type: String,
      default: '/static/point.png'
    }
  },
  components: {
    CheckOutlined
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.position, {}),
      map: null,
      marker: null,
      geocoder: null,
      activeIndex: null, // 选中的索引
      addressList: []
    }
  },
  watch: {
    position() {
      console.log('watch', this.position)
      if (this.position.longitude) {
        this.form = this.position
        this.form.lnglat =
          this.position.longitude + ',' + this.position.latitude
        const lnglat = new AMap.LngLat(
          this.position.longitude,
          this.position.latitude
        )
        this.addMarker(lnglat)
      } else {
        if (this.marker) {
          this.marker.setMap(null)
        }
        this.addressList = []
        this.form = {}
      }
    }
  },
  created() {},
  mounted() {
    this.init()
  },
  methods: {
    selectAddress(item, index) {
      this.activeIndex = index
      const longitude = item.location.lng
      const latitude = item.location.lat
      this.form.lnglat = longitude + ',' + latitude
      this.form.fullAddress = item.name
    },
    // 将经纬度改为具体地址名称
    regeoCode(lnglat) {
      const that = this
      that.geocoder.getAddress(lnglat, function (status, result) {
        if (status === 'complete' && result.regeocode) {
          console.log('res', result.regeocode)
          that.addressList = result.regeocode.pois
          var address = result.regeocode.formattedAddress
          that.form.fullAddress = address
        } else {
          that.form.fullAddress = ''
          console.log('获取地址失败')
        }
      })
    },
    /* 保存编辑 */
    init() {
      const that = this
      that.map = new AMap.Map(this.id, {
        center: [118.08923, 24.479406],
        resizeEnable: true,
        zoom: 10
      })
      AMap.plugin(['AMap.ToolBar', 'AMap.Scale', 'AMap.Geocoder'], function () {
        that.map.addControl(new AMap.ToolBar())
        that.map.addControl(new AMap.Scale())
        that.geocoder = new AMap.Geocoder({
          city: '全国',
          extensions: 'all'
        })
      })
      that.map.on('click', this.showInfoClick)
      if (that.position.longitude) {
        const lnglat = new AMap.LngLat(
          that.position.longitude,
          that.position.latitude
        )
        that.addMarker(lnglat)
      } else if (this.marker) {
        this.marker.setMap(null)
      }
    },
    search() {
      const that = this
      AMap.service(['AMap.PlaceSearch'], function () {
        const placeSearch = new AMap.PlaceSearch({
          pageIndex: 1
        })
        placeSearch.search(that.form.fullAddress, function (status, result) {
          const pois = result.poiList.pois
          if (pois) {
            const poi = pois[0]
            that.addMarker(poi.location)
            that.map.setCenter(poi.location)
          }
        })
      })
    },
    setForm(lnglat) {
      this.form.lnglat = lnglat.getLng() + ',' + lnglat.getLat()
      this.regeoCode(this.form.lnglat)
      this.form.longitude = lnglat.getLng()
      this.form.latitude = lnglat.getLat()
      this.activeIndex = null
    },
    addMarker(lnglat) {
      if (this.marker) {
        this.marker.setMap(null)
      }
      const startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(32, 32),
        // 图标的取图地址
        image: this.iconUrl,
        imageOffset: new AMap.Pixel(0, 0),
        // 图标所用图片大小
        imageSize: new AMap.Size(32, 32)
      })
      this.marker = new AMap.Marker({
        icon: startIcon,
        position: lnglat,
        offset: new AMap.Pixel(-16, -40)
      })
      this.marker.setMap(this.map)
      this.setForm(lnglat)
    },
    showInfoClick(e) {
      this.addMarker(e.lnglat)
    },
    done() {
      this.$emit('done', this.form)
    }
  }
}
</script>

<style scoped lang="less">
.scroll-list {
  width: 100%;
  height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 10px;
  .item {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #909399;
    .title {
      font-weight: bold;
      color: #000;
    }
    .desc {
      color: #909399;
    }
  }
}
</style>
